import React from 'react';
import Layout from '../src/hoc/Layout/Layout'

function App() {
  return (
      <Layout>

      </Layout>
  );
}

export default App;
